import {Injectable} from '@angular/core';
import * as $ from 'jquery';
import {LocationIntelligenceService, Route, Segment} from './location-intelligence.service';


@Injectable()
export class GuiService {

  public locationIntelligenceService: LocationIntelligenceService;

  private currentReasonForMainSpinnerStack = [];
  private currentRequestIdStack = [];
  private currentPopupIdStack = [];
  public sidePanelIsExpanded = false;

  public showDebugConsole = false;


  constructor() {
    this.initKeyDownEventListener();
    this.initDebugModeState();
  }

  private initKeyDownEventListener() {
    document.addEventListener('keydown', (e) => {
      const keyCode = e.keyCode;
      if (keyCode === 164) {
        this.showDebugConsole = !this.showDebugConsole;
      }
    }, false);
  }


  private initDebugModeState() {
    console.log('debugModeState:', this.getDebugModeState());
    window['toggleDebugMode'] = () => this.toggleDebugMode();
  }


  public getDebugModeState(): boolean {
    if (localStorage.getItem('useDebugMode') === 'true') {
      return true;
    } else {
      return false;
    }
  }


  private toggleDebugMode() {
    const newDebugModeState = !this.getDebugModeState();
    localStorage.setItem('useDebugMode', '' + newDebugModeState);
    console.log('debugModeState:', this.getDebugModeState());
    return;
  }


  public goHome() {
    location.reload();
  }


  public togglePanel() {
    this.sidePanelIsExpanded = !this.sidePanelIsExpanded;
  }

  public closePanel() {
    this.sidePanelIsExpanded = false;
  }

  public addRequestId(id: string): void {
    this.currentRequestIdStack.push(id);
  }

  public removeRequestId(id: string) {
    this.currentRequestIdStack.splice(
      this.currentRequestIdStack.indexOf(id), 1
    );
  }

  public hasRequestId(): boolean {
    return this.currentRequestIdStack.length > 0;
  }

  public addPopupId(id: string): void {
    this.currentPopupIdStack.push(id);
  }

  public removePopupId(id: string) {
    this.currentPopupIdStack.splice(
      this.currentPopupIdStack.indexOf(id), 1
    );
  }

  public hasOpenPopupId(): boolean {
    return this.currentPopupIdStack.length > 0;
  }

  public refreshRouteExpansions(currentRoutes: Route[]) {
    currentRoutes.forEach((route: Route) => {
      if (route.expanded) {
        $('#route' + route.id).slideDown(200);
      } else {
        $('#route' + route.id).slideUp(200);
      }
      route.segments.forEach((segment: Segment) => {
        if (segment.expanded) {
          $('#segment' + segment.id).slideDown(200);
        } else {
          $('#segment' + segment.id).slideUp(200);
        }
      });
    });
  }

  public addReasonForMainSpinner(id: string): void {
    setTimeout(() => {
      if (this.hasReasonForMainSpinner()) {
        this.showMainSpinner();
      }
    }, 1000);
    this.currentReasonForMainSpinnerStack.push(id);
  }

  public removeReasonForMainSpinner(id: string): void {
    if (this.currentReasonForMainSpinnerStack.indexOf(id) > -1) {
      this.currentReasonForMainSpinnerStack.splice(this.currentReasonForMainSpinnerStack.indexOf(id), 1);
    }
    if (!this.hasReasonForMainSpinner()) {
      this.hideMainSpinner();
    }
  }

  public hasReasonForMainSpinner(): boolean {
    return this.currentReasonForMainSpinnerStack.length > 0;
  }

  public showMainSpinner(): void {
    $('#mainSpinner').fadeIn(500);
    setTimeout(() => {
      $('#mainSpinner .spinner-container').fadeIn(500);
    }, 200);

  }

  public hideMainSpinner(): void {
    setTimeout(() => {
      $('#mainSpinner').fadeOut(500);
      $('#mainSpinner .spinner-container').fadeOut(500);
    }, 200);
  }


}
