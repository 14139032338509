import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {LocationIntelligenceService, MarkerDataPreview, MarkerType} from '../services/location-intelligence.service';
import {Observable, Subscription} from 'rxjs';
import 'leaflet.markercluster';
import 'polyline-encoded';
import {debounceTime, startWith, switchMap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {AutocompleteResultLikeApi, LatLng, NodeLikeApi} from '../services/api-service';
import {MatAutocomplete, MatAutocompleteTrigger} from '@angular/material';
import {SettingService} from '../services/setting-service';


export interface AutocompleteParams {
  isFrom: boolean;
  isTo: boolean;
}

@Component({
  selector: 'app-search-component',
  template: `

    <div class="search-autocomplete">
      <mat-form-field>
        <input matInput
               [placeholder]="getLabel()"
               aria-label="Suche"
               [matAutocomplete]="auto"
               [formControl]="formControl">
        <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true" [displayWith]="displayWithFn">
          <mat-option *ngFor="let option of filteredOptions | async"
                      (onSelectionChange)="onSelect($event)"
                      [value]="option">
            <span>{{getOptionText(option)}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div class="result"
           *ngIf="getThisMarkerDataPreview()"
           (click)="locationIntelligenceService.focusMarker(getThisMarkerDataPreview(), true)"
           [ngClass]="{'from': autocompleteParams.isFrom, 'to': autocompleteParams.isTo}">
        {{ getThisMarkerDataPreview().name }}
        <button class="remove" (click)="removeMarker()"></button>
      </div>

      <button class="geolocation" (click)="makeGeolocation()"></button>
    </div>

  `,
})
export class SearchComponent implements OnInit, OnDestroy {

  @Input() autocompleteParams: AutocompleteParams;

  public formControl = new FormControl();
  public filteredOptions: Observable<any>;
  private subscription: Subscription;
  @Output() valueChange = new EventEmitter();

  constructor(
    public locationIntelligenceService: LocationIntelligenceService,
    public settingService: SettingService,
  ) {
  }

  ngOnInit(): void {
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith<any>(''),
      debounceTime(150),
      switchMap(needle => {
        return this.locationIntelligenceService.getAutocomplete(needle);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public removeMarker(): void {
    if (this.autocompleteParams.isFrom) {
      this.settingService.setCurrentRoutingMarker(0, null);
    } else if (this.autocompleteParams.isTo) {
      this.settingService.setCurrentRoutingMarker(1, null);
    } else {
      return null;
    }
  }

  public getThisMarkerDataPreview(): MarkerDataPreview {
    if (this.autocompleteParams.isFrom) {
      return this.settingService.getCurrentRoutingMarker()[0];
    } else if (this.autocompleteParams.isTo) {
      return this.settingService.getCurrentRoutingMarker()[1];
    } else {
      return null;
    }
  }

  public getLabel(): string {
    return (this.autocompleteParams.isFrom) ? 'von' : 'nach';
  }

  public getOptionText(option: AutocompleteResultLikeApi) {
    return option.name;
  }

  public displayWithFn(option: AutocompleteResultLikeApi) {
    if (option) {
      return '';
    }
  }

  public onSelect(event) {
    if (event.source.selected) {
      const autocompleteOption: AutocompleteResultLikeApi = event.source.value;
      const markerDataPreview = this.getMarkerPreviewFromAutocompleteOption(autocompleteOption);
      if (this.autocompleteParams.isFrom) {
        this.locationIntelligenceService.defineRoutingStartingMarker(markerDataPreview, true);
        this.locationIntelligenceService.refreshRouting();
      }
      if (this.autocompleteParams.isTo) {
        this.locationIntelligenceService.defineRoutingEndingMarker(markerDataPreview, true);
        this.locationIntelligenceService.refreshRouting();
      }
    }
  }

  public getMarkerPreviewFromAutocompleteOption(autocompleteOption: AutocompleteResultLikeApi): MarkerDataPreview {
    return {
      id: autocompleteOption.key,
      name: autocompleteOption.name,
      coordinates: {
        latitude: autocompleteOption.coordinates.latitude,
        longitude: autocompleteOption.coordinates.longitude,
      },
      markerType: this.getMarkerTypeFromAutocompleteOption(autocompleteOption)
    };
  }

  public getMarkerTypeFromAutocompleteOption(option: AutocompleteResultLikeApi): MarkerType {
    if (option.type === 'station') {
      return MarkerType.STATION;
    } else {
      return MarkerType.CUSTOM_PIN;
    }
  }


  public makeGeolocation(): void {
    this.locationIntelligenceService.getGeolocation((latLng: LatLng) => {
      if (latLng) {
        this.locationIntelligenceService.getNextNode(latLng).subscribe((nodeLikeApi: NodeLikeApi) => {
          const markerDataPreview: MarkerDataPreview = {
            name: nodeLikeApi.name,
            id: nodeLikeApi.key,
            coordinates: nodeLikeApi.coordinates,
            markerType: MarkerType.CUSTOM_PIN,
          };
          if (this.autocompleteParams.isFrom) {
            this.settingService.setCurrentRoutingMarker(0, markerDataPreview);
          } else if (this.autocompleteParams.isTo) {
            this.settingService.setCurrentRoutingMarker(1, markerDataPreview);
          }
        });
      } else {
        alert('Ihr Standort konnte nicht bestimmt werden.');
      }
    });
  }
}
