import {Injectable} from '@angular/core';
import {Departure, LocationIntelligenceService, MarkerDataPreview, Route, Segment} from './location-intelligence.service';


export enum Mode {
  'from' = '1',
  'to' = '2',
}

@Injectable()
export class SettingService {

  private customDateTime: Date = null;

  private currentRoutingMarker: MarkerDataPreview[] = [null, null];

  private currentDeparture: Departure = null;

  private mode: Mode = Mode.from;

  public locationIntelligenceService: LocationIntelligenceService;

  constructor() {
  }

  public setMode(mode: Mode): void {
    this.mode = mode;
    this.locationIntelligenceService.refreshRouting();
  }

  public getMode(): Mode {
    return this.mode;
  }

  public getCurrentDeparture(): Departure {
    return this.currentDeparture;
  }

  public setCurrentDeparture(departure: Departure): void {
    this.currentDeparture = departure;
    this.locationIntelligenceService.makeDepartureTrip(departure);
  }

  public allRoutingMarkerAreDefined(): boolean {
    return !!this.currentRoutingMarker[0] && !!this.currentRoutingMarker[1];
  }

  public setCurrentRoutingMarker(index: number, markerDataPreview: MarkerDataPreview) {
    this.currentRoutingMarker[index] = markerDataPreview;
    this.locationIntelligenceService.refreshRouting();
  }

  public resetAllRoutingMarker(): void {
    this.currentRoutingMarker.forEach((_, index) => {
      this.currentRoutingMarker[index] = null;
    });
    this.locationIntelligenceService.refreshRouting();
  }

  public getCurrentRoutingMarker(): MarkerDataPreview[] {
    return this.currentRoutingMarker;
  }

  public setTimeOfCustomDateTime(time: string): void {
    if (!time) {
      this.resetCustomDateTime();
    } else {
      const hoursAndMinutes: string[] = time.split(':');
      const newDateTime = this.getDateTime();
      newDateTime.setHours(+hoursAndMinutes[0]);
      newDateTime.setMinutes(+hoursAndMinutes[1]);
      newDateTime.setSeconds(1);
      this.customDateTime = newDateTime;
    }
    this.locationIntelligenceService.refreshRouting();
  }

  public getTimeOfDateTime(): string {
    const dateTime = this.getDateTime();
    const hours = ('0' + (dateTime.getHours())).slice(-2);
    const minutes = ('0' + (dateTime.getMinutes())).slice(-2);
    return '' + hours + ':' + minutes;
  }

  public setDateOfCustomDateTime(date: Date): void {
    const newDateTime = this.getDateTime();
    newDateTime.setDate(date.getDate());
    newDateTime.setMonth(date.getMonth());
    newDateTime.setFullYear(date.getFullYear());
    this.customDateTime = newDateTime;
    this.locationIntelligenceService.refreshRouting();
  }

  public resetCustomDateTime(): void {
    this.customDateTime = null;
  }

  public hasCustomDateTime(): boolean {
    return this.customDateTime !== null;
  }

  public getDateTime(): Date {
    if (!!this.customDateTime) {
      return this.customDateTime;
    } else {
      return new Date();
    }
  }
}
