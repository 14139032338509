import {ApplicationRef, ComponentFactoryResolver, Injectable, Injector} from '@angular/core';
import {GuiService} from './gui-service';
import {Departure, LocationIntelligenceService, MarkerDataPreview, MarkerType, Route, Segment} from './location-intelligence.service';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'polyline-encoded';
import * as $ from 'jquery';
import {Bounds, LatLng, NodeLikeApi, OtherStationLikeApi, StopLikeApi, SubsegmentLikeApi, Vehicle} from './api-service';
import {PopupComponent} from '../components/popup.component';

// ------------------------------------------------------------------------------------------------------------------------------- //

export enum MarkerState {
  'DEFAULT' = 'default',
  'FOCUS' = 'focus',
  'ROUTE_START' = 'route-start',
  'ROUTE_END' = 'route-end',
  'ROUTE_SEGMENT_FOCUS' = 'route-segment-focus',
  'VEHICLE_CHANGE' = 'vehicle-change',
  'DEPARTURE_TRIP' = 'trip',
}

export enum MarkerImageState {
  'DEFAULT' = 'default',
  'ROUTE' = 'route',
  'FOCUS' = 'focus',
  'DEPARTURE_TRIP' = 'trip',
}

export enum MarkerImageName {
  'TRAM' = 'TRAM',
  'BUS' = 'BUS',
  'TROLLEY' = 'TROLLEY',
  'METRO' = 'METRO',
  'RAIL' = 'RAIL',
  'CARTRAIN' = 'CARTRAIN',
  'BOAT' = 'BOAT',
  'CABLECAR' = 'CABLECAR',
  'GONDOLA' = 'GONDOLA',
  'FUNICULAR' = 'FUNICULAR',
  'FLAG' = 'FLAG',
  'PIN' = 'PIN',
  'STATION' = 'STATION',
  'PARKING' = 'PARKING',
  'EXCLAMATION' = 'EXCLAMATION',
  'PUBLIBIKE' = 'PUBLIBIKE',
}

export type LatLngAsArray = number[][];

export interface BoundsAndZoom {
  bounds: Bounds;
  zoom: number;
}

// ------------------------------------------------------------------------------------------------------------------------------- //

@Injectable()
export class MapService {

  public locationIntelligenceService: LocationIntelligenceService;

  public compRef: any;
  public map;
  private mapLayer = {
    layerWithLocationIntelligence: L.featureGroup(),
    layerWithFocusSegment: L.featureGroup(),
    layerWithFocusSegmentLongShadow: L.featureGroup(),
    layerWithTemporaryFocusSegment: L.featureGroup(),
    layerWithFocusedMarker: L.featureGroup(),
    layerWithRouteStartMarker: L.featureGroup(),
    LayerWithRouteEndMarker: L.featureGroup(),
    layerWithRoute: L.featureGroup(),
    layerWithDepartureTrip: L.featureGroup(),
  };

  private mapSettings = {
    coords: [46.948094, 7.444096],
    zoom: 15,
    tyleLayers: [
      {
        name: 'Normal',
        url: 'https://api.mapbox.com/styles/v1/rolandlos/cjy48jtb50e9e1coesthqzbi2/tiles/256/{z}/{x}/{y}' +
          '?access_token=pk.eyJ1Ijoicm9sYW5kbG9zIiwiYSI6Im0zZUNwcm8ifQ.x_iNKLiQDcixBb5-p6Nyfw'
      }
    ]
  };

  public utils = {
    getFirstPoint: (polyline: any) => {
      const points = polyline.getLatLngs();
      return points[0] as LatLngAsArray;
    },
    getLastPoint: (polyline: any) => {
      const points = polyline.getLatLngs();
      return points[points.length - 1] as LatLngAsArray;
    },
  };

  constructor(
    private guiService: GuiService,
    private injector: Injector,
    private appRef: ApplicationRef,
    private resolver: ComponentFactoryResolver,
  ) {
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public resetAllMapLayer() {
    for (const key in this.mapLayer) {
      if (this.mapLayer[key]) {
        this.mapLayer[key].clearLayers();
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public closeAllPopups(): void {
    this.map.closePopup();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public generateMap() {
    if (this.map !== undefined) {
      this.map.removeMarker();
    }
    this.map = L.map('leafletMap', {
      attributionControl: false,
      layers: [L.tileLayer(this.mapSettings.tyleLayers[0].url, {maxZoom: 20})]
    });
    for (const key in this.mapLayer) {
      if (this.mapLayer[key]) {
        this.map.addLayer(this.mapLayer[key]);
      }
    }

    this.map.setView(this.mapSettings.coords, this.mapSettings.zoom);
    this.map.on('moveend', () => {
      this.locationIntelligenceService.refreshLocationIntelligence();
    });

    this.map.on('click', (e) => {
      if (!this.guiService.hasOpenPopupId()) {
        this.createNewPinMarker(e.latlng);
      }
    });
    this.locationIntelligenceService.refreshLocationIntelligence();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public createNewPinMarker(latLng: LatLng) {
    this.locationIntelligenceService.getNextNode(latLng)
      .subscribe((nodeLikeApi: NodeLikeApi) => {
        const markerDataPreview: MarkerDataPreview = {
          name: nodeLikeApi.name,
          id: nodeLikeApi.key,
          coordinates: nodeLikeApi.coordinates,
          markerType: MarkerType.CUSTOM_PIN,
        };
        this.locationIntelligenceService.focusMarker(markerDataPreview);
      });
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public getBoundsAndZoom(): BoundsAndZoom {
    return {
      bounds: this.map.getBounds(),
      zoom: this.map.getZoom()
    };
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public printLocationIntelligenceMarker(locationIntelligenceMarkerDataPreview: MarkerDataPreview[]): void {
    this.mapLayer.layerWithLocationIntelligence.clearLayers();
    locationIntelligenceMarkerDataPreview.forEach((markerDataPreview: MarkerDataPreview) => {
      if (this.markerHasCoordinates(markerDataPreview)) {
        const marker = this.getLeafletMarker(markerDataPreview, MarkerState.DEFAULT)
          .on('click', (e) => {
            this.locationIntelligenceService.focusMarker(markerDataPreview);
          });
        this.mapLayer.layerWithLocationIntelligence.addLayer(marker);
      }
    });
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public printFocusMarker(markerDataPreview: MarkerDataPreview, fitBound?: boolean) {
    this.mapLayer.layerWithFocusedMarker.clearLayers();
    const popupId = '' + Math.random().toFixed(10);
    const marker = this.getLeafletMarker(markerDataPreview, MarkerState.FOCUS).bindPopup(null)
      .on('popupopen', (e) => {
        if (this.compRef) {
          this.compRef.destroy();
        }
        this.guiService.addPopupId(popupId);
        const compFactory = this.resolver.resolveComponentFactory(PopupComponent);
        this.compRef = compFactory.create(this.injector);
        this.compRef.instance.params = {
          markerDataPreview: markerDataPreview,
          mapComponent: this,
        };
        this.appRef.attachView(this.compRef.hostView);
        this.compRef.onDestroy(() => {
          this.appRef.detachView(this.compRef.hostView);
        });
        const div = document.createElement('div');
        div.appendChild(this.compRef.location.nativeElement);
        marker.setPopupContent(div);
        $(e.target._icon).addClass('_open');
      }).on('popupclose', (e) => {
        setTimeout(() => {
          if (!this.guiService.hasOpenPopupId()) {
            this.mapLayer.layerWithFocusedMarker.clearLayers();
          }
        }, 250);
        setTimeout(() => {
          this.guiService.removePopupId(popupId);
        }, 100);
        $(e.target._icon).removeClass('_open');
      });
    this.mapLayer.layerWithFocusedMarker.addLayer(marker);
    marker.openPopup();
    if (fitBound) {
      this.map.fitBounds(this.mapLayer.layerWithFocusedMarker.getBounds(), {
        paddingTopLeft: L.point(300, 300),
        paddingBottomRight: L.point(300, 300),
        maxZoom: 16,
      });
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public removeFocusMarker() {
    this.mapLayer.layerWithFocusedMarker.clearLayers();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public markerHasCoordinates(markerDataPreview: MarkerDataPreview): boolean {
    const answer: boolean = !!(markerDataPreview.coordinates && markerDataPreview.coordinates.latitude && markerDataPreview.coordinates.longitude);
    return answer;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public printRouteStartAndEndMarker(startMarkerPreview: MarkerDataPreview, endMarkerPreview: MarkerDataPreview) {
    this.mapLayer.layerWithRouteStartMarker.clearLayers();
    if (startMarkerPreview && this.markerHasCoordinates(startMarkerPreview)) {
      const marker = this.getLeafletMarker(startMarkerPreview, MarkerState.ROUTE_START)
        .on('click', (e) => {
          this.locationIntelligenceService.focusMarker(startMarkerPreview);
        });
      this.mapLayer.layerWithRouteStartMarker.addLayer(marker);
    }
    this.mapLayer.LayerWithRouteEndMarker.clearLayers();
    if (endMarkerPreview && this.markerHasCoordinates(endMarkerPreview)) {
      const marker = this.getLeafletMarker(endMarkerPreview, MarkerState.ROUTE_END)
        .on('click', (e) => {
          this.locationIntelligenceService.focusMarker(endMarkerPreview);
        });
      this.mapLayer.LayerWithRouteEndMarker.addLayer(marker);
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public printRoute(route: Route) {
    this.mapLayer.layerWithRoute.clearLayers();
    if (route) {
      route.segments.forEach((segment: Segment, segmentIndtex) => {
        segment.activSubsegments.forEach((subsegment: SubsegmentLikeApi, subsegmentIndex) => {
          if (subsegment.points) {
            // POLYLINE
            let cssClass = 'lic-route-polyline';
            if (segment.vehicle === Vehicle.WALK) {
              cssClass += ' _walk';
            }
            const polyline = L.polyline(L.PolylineUtil.decode(subsegment.points, 6), {
              bubblingMouseEvents: false,
              className: cssClass,
            }).on('click', () => {
              this.locationIntelligenceService.setFocusSegment(segment);
            }).on('mouseover', () => {
              this.locationIntelligenceService.setTemporaryFocusSegment(segment);
            }).on('mouseout', () => {
              this.locationIntelligenceService.removeTemporaryFocusSegment();
            });
            this.mapLayer.layerWithRoute.addLayer(polyline);
            polyline.bringToBack();
            // POINTS
            const makePoint = (latLng, station: OtherStationLikeApi) => {
              this.mapLayer.layerWithRoute.addLayer(L.circle(latLng, {
                radius: 1,
                className: 'lic-route-circle',
              }).on('click', () => {
                this.locationIntelligenceService.focusMarker(
                  this.locationIntelligenceService.getMarkerPreviewFromOtherStation(station, segment.vehicle)
                );
              }));
            };
            makePoint(this.utils.getFirstPoint(polyline), subsegment.from);
            if (subsegmentIndex + 1 === segment.activSubsegments.length) {
              makePoint(this.utils.getLastPoint(polyline), subsegment.to);
            }
          }
        });

        const vehicleChangeMarkerArray: MarkerDataPreview[] = [];
        if (segmentIndtex !== 0 && segment.vehicle !== Vehicle.WALK) {
          vehicleChangeMarkerArray.push(
            this.locationIntelligenceService.getMarkerPreviewFromOtherStation(segment.to, segment.vehicle)
          );
        }
        if (segmentIndtex !== 0 && route.segments[segmentIndtex - 1].vehicle === Vehicle.WALK) {
          vehicleChangeMarkerArray.push(
            this.locationIntelligenceService.getMarkerPreviewFromOtherStation(segment.from, segment.vehicle)
          );
        }
        vehicleChangeMarkerArray.forEach((vehicleChangeMarker: MarkerDataPreview) => {
          if (this.markerHasCoordinates(vehicleChangeMarker)) {
            const marker = this.getLeafletMarker(vehicleChangeMarker, MarkerState.VEHICLE_CHANGE)
              .on('click', (e) => {
                this.locationIntelligenceService.focusMarker(vehicleChangeMarker);
              });
            this.mapLayer.layerWithRoute.addLayer(marker);
          }
        });
      });
    }
    this.map.fitBounds(this.mapLayer.layerWithRoute.getBounds(), {
      paddingTopLeft: [250, 0]
    });
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public printFocusSegment(segment: Segment, isTemporary: boolean) {
    let leafletLayer = null;
    if (isTemporary) {
      leafletLayer = this.mapLayer.layerWithTemporaryFocusSegment;
    } else {
      leafletLayer = this.mapLayer.layerWithFocusSegment;
    }
    leafletLayer.clearLayers();
    if (!isTemporary) {
      this.mapLayer.layerWithFocusSegmentLongShadow.clearLayers();
      segment.subsegments.forEach((subsegment: SubsegmentLikeApi, subsegmentIndex) => {
        if (subsegment.points) {
          // POLYLINE
          let cssClass = 'lic-route-polyline _long-shadow';
          if (segment.vehicle === Vehicle.WALK) {
            cssClass += ' _walk';
          }
          const polyline = L.polyline(L.PolylineUtil.decode(subsegment.points, 6), {
            bubblingMouseEvents: false,
            className: cssClass
          });
          this.mapLayer.layerWithFocusSegmentLongShadow.addLayer(polyline);
          polyline.bringToBack();
          // POINTS
          const makePoint = (latLng: LatLngAsArray, station: OtherStationLikeApi) => {
            const leafletPoint = L.circle(latLng, {
              radius: 1,
              className: 'lic-route-circle _long-shadow',
            }).on('click', () => {
              this.locationIntelligenceService.focusMarker(
                this.locationIntelligenceService.getMarkerPreviewFromOtherStation(station, segment.vehicle)
              );
            });
            this.mapLayer.layerWithFocusSegmentLongShadow.addLayer(leafletPoint);
            // leafletPoint.bringToBack();
          };
          makePoint(this.utils.getFirstPoint(polyline), subsegment.from);
          if (subsegmentIndex + 1 === segment.activSubsegments.length) {
            makePoint(this.utils.getLastPoint(polyline), subsegment.to);
          }
        }
      });
    }
    segment.activSubsegments.forEach((subsegment: SubsegmentLikeApi, subsegmentIndex) => {
      if (subsegment.points) {
        // POLYLINE
        let cssClass = 'lic-route-polyline _segment-focus';
        if (segment.vehicle === Vehicle.WALK) {
          cssClass += ' _walk';
        }
        const polyline = L.polyline(L.PolylineUtil.decode(subsegment.points, 6), {
          bubblingMouseEvents: false,
          className: cssClass
        });
        leafletLayer.addLayer(polyline);
        polyline.bringToBack();
        // POINTS
        const makePoint = (latLng, station: OtherStationLikeApi) => {
          const leafletPoint = L.circle(latLng, {
            radius: 1,
            className: 'lic-route-circle _segment-focus',
          });
          leafletLayer.addLayer(leafletPoint);
          leafletPoint.bringToBack();
        };
        makePoint(this.utils.getLastPoint(polyline), subsegment.from);
        if (subsegmentIndex + 1 === segment.activSubsegments.length) {
          makePoint(this.utils.getFirstPoint(polyline), subsegment.to);
        }
      }
      // MARKER
      if (!isTemporary) {
        const markerDataPreview = this.locationIntelligenceService.getMarkerPreviewFromOtherStation(subsegment.from, segment.vehicle);
        if (this.markerHasCoordinates(markerDataPreview)) {
          const marker = this.getLeafletMarker(markerDataPreview, MarkerState.ROUTE_SEGMENT_FOCUS)
            .on('click', (e) => {
              this.locationIntelligenceService.focusMarker(markerDataPreview);
            });
          leafletLayer.addLayer(marker);
        }
      }
    });
    if (!isTemporary) {
      this.map.fitBounds(leafletLayer.getBounds(), {
        paddingTopLeft: [250, 0]
      });
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public removeFocusSegment(isTemporary: boolean): void {
    if (isTemporary) {
      this.mapLayer.layerWithTemporaryFocusSegment.clearLayers();
    } else {
      this.mapLayer.layerWithFocusSegmentLongShadow.clearLayers();
      this.mapLayer.layerWithFocusSegment.clearLayers();
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public removeRoute(): void {
    this.mapLayer.layerWithRoute.clearLayers();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public printDepartureTrip(departure: Departure) {
    this.mapLayer.layerWithDepartureTrip.clearLayers();
    if (departure && departure.departureTrip) {

      // POLYLINE
      if (departure.departureTrip.polylines) {
        departure.departureTrip.polylines.forEach((polyline: string) => {
          const leafletPolyline = L.polyline(L.PolylineUtil.decode(polyline, 6), {
            bubblingMouseEvents: false,
            className: 'departure-trip-polyline',
          });
          this.mapLayer.layerWithDepartureTrip.addLayer(leafletPolyline);
        });
      }

      // MARKER
      if (departure.departureTrip.stops) {
        departure.departureTrip.stops.forEach((stop: StopLikeApi) => {
          const markerDataPreview = this.locationIntelligenceService.getMarkerPreviewFromStop(stop, departure.route.vehicleId);
          if (this.markerHasCoordinates(markerDataPreview)) {
            const leafletMarker = this.getLeafletMarker(markerDataPreview, MarkerState.DEPARTURE_TRIP)
              .on('click', (e) => {
                this.locationIntelligenceService.focusMarker(markerDataPreview);
              });
            this.mapLayer.layerWithDepartureTrip.addLayer(leafletMarker);
          }
        });
      }
    }

    this.map.fitBounds(this.mapLayer.layerWithDepartureTrip.getBounds(), {
      paddingTopLeft: [250, 0]
    });
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public getLeafletMarker(markerDataPreview: MarkerDataPreview, markerState: MarkerState) {

    let cssClass = 'lic-marker';
    let markerImageState: MarkerImageState;
    let markerImageName: MarkerImageName;
    let zIndex: number;

    // MARKER-STATE
    if (markerState === MarkerState.FOCUS) {
      markerImageState = MarkerImageState.FOCUS;
      cssClass += ' _focus';
      zIndex = 8000;
    } else if (markerState === MarkerState.DEPARTURE_TRIP) {
      markerImageState = MarkerImageState.DEPARTURE_TRIP;
      zIndex = 3000;
    } else if (markerState === MarkerState.ROUTE_START) {
      markerImageState = MarkerImageState.ROUTE;
      cssClass += ' _route _start';
      zIndex = 6000;
    } else if (markerState === MarkerState.ROUTE_END) {
      markerImageState = MarkerImageState.ROUTE;
      cssClass += ' _route _end';
      zIndex = 6000;
    } else if (markerState === MarkerState.VEHICLE_CHANGE) {
      markerImageState = MarkerImageState.DEFAULT;
      cssClass += ' _vehicle-change';
      zIndex = 4000;
    } else if (markerState === MarkerState.ROUTE_SEGMENT_FOCUS) {
      markerImageState = MarkerImageState.ROUTE;
      cssClass += ' _route _segment-focus';
      zIndex = 5000;
    } else {
      markerImageState = MarkerImageState.DEFAULT;
      zIndex = 0;
    }

    // MARKER-IMAGE
    if (markerDataPreview.markerType === MarkerType.CUSTOM_PIN) {
      markerImageName = MarkerImageName.PIN;
    } else if (markerDataPreview.markerType === MarkerType.STATION) {
      markerImageName = MarkerImageName.STATION;
      if (markerDataPreview.markerType !== undefined) {
        markerImageName = MarkerImageName[this.locationIntelligenceService.getVehicleIconString(markerDataPreview.vehicle)];
      }
    } else if (markerDataPreview.markerType === MarkerType.POI_PARKING) {
      markerImageName = MarkerImageName.PARKING;
    } else if (markerDataPreview.markerType === MarkerType.POI_PUBLIBIKE) {
      markerImageName = MarkerImageName.PUBLIBIKE;
    } else {
      markerImageName = MarkerImageName.FLAG;
    }

    // MARKER
    const imageUrl = `./assets/img/marker/${markerImageState}/${markerImageName}.png`;
    const markerHtml = `<div class="marker-image" style="background-image: url('${imageUrl}')"></div>`;
    const marker = L.marker([markerDataPreview.coordinates.latitude, markerDataPreview.coordinates.longitude], {
      icon: L.divIcon({
        iconSize: [36, 45],
        iconAnchor: [18, 40],
        popupAnchor: [15, -39],
        html: markerHtml,
        className: cssClass,
      })
    });
    marker.setZIndexOffset(zIndex);
    return marker;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public getMapCenter(): LatLng {
    if (this.map) {
      return this.map.getCenter();
    } else {
      return null;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public completePointsWithMarkerPosition(segment: Segment): void {
    segment.subsegments.forEach((subsegment: SubsegmentLikeApi) => {
      if (!subsegment.points) {
        const point1: number[] = [subsegment.from.latitude, subsegment.from.longitude];
        const point2: number[] = [subsegment.to.latitude, subsegment.to.longitude];
        subsegment.points = L.PolylineUtil.encode([point1, point2], 6);
      }
    });
  }
}
