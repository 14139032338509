import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {ApiService} from './services/api-service';
import {GuiService} from './services/gui-service';
import {LocationIntelligenceService} from './services/location-intelligence.service';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {PopupComponent} from './components/popup.component';
import {MapComponent} from './components/map.component';
import {MapService} from './services/map-service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule, MatNativeDateModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SearchComponent} from './components/search.component';
import {RoutingComponent} from './components/routing.component';
import {SettingService} from './services/setting-service';
import {DateTimeComponent} from './components/dateTime.component';
import {SpinnerComponent} from './components/spinner.component';
registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    RoutingComponent,
    PopupComponent,
    SearchComponent,
    DateTimeComponent,
    SpinnerComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    LocationIntelligenceService,
    { provide: LOCALE_ID, useValue: 'de' },
    ApiService,
    GuiService,
    DatePipe,
    MapService,
    SettingService,
  ],
  entryComponents: [
    PopupComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
