import {AfterViewInit, Component, Input} from '@angular/core';
import {LocationIntelligenceService, Route, Segment} from '../services/location-intelligence.service';
import {GuiService} from '../services/gui-service';
import {OtherStationLikeApi, SegmentLikeApi, SubsegmentLikeApi, Vehicle} from '../services/api-service';
import {SettingService} from '../services/setting-service';


@Component({
  selector: 'app-routing-component',
  template: `

    <div class="routing">

      <div class="title"
           *ngIf="settingService.allRoutingMarkerAreDefined()">
        <div class="from"> {{settingService.getCurrentRoutingMarker()[0].name}}</div>
        <div class="to"> {{settingService.getCurrentRoutingMarker()[1].name}}</div>
      </div>

      <div class="route"
           *ngFor="let route of locationIntelligenceService.currentRoutes">

        <button class="route-header"
                (click)="locationIntelligenceService.setRoute(route)">
          <span>Ab: {{ route.segments[0].departure | date: 'HH:mm'}} </span>
          <span *ngIf="route.segments[0].departureDelay; let delay">{{ delay }}</span>
          |
          <span>An: {{ route.lastSegment.arrival | date: 'HH:mm'}} </span>
          <span *ngIf="route.lastSegment.arrivalDelay; let delay">{{ delay }}</span>
          <br>
          {{ getTime(route.timeOnRoute) }} Minuten Fahrzeit
        </button>

        <div class="route-body" [id]="'route'+route.id">

          <div class="segment-row _button"
               [ngClass]="{'_focus': segment.focus}"
               (mouseenter)="locationIntelligenceService.setTemporaryFocusSegment(segment)"
               (mouseleave)="locationIntelligenceService.removeTemporaryFocusSegment()"
               *ngFor="let segment of route.segments"
               (click)="toggleSegmentDetails(segment)">

            <div class="column _left">
              <div class="time">{{segment.departure | date: 'HH:mm'}}
                <div class="delay">{{segment.departureDelay}}</div>
              </div>
              <div class="vehicle-icon"
                   [style.background-image]="'url(' + getIconUrl(segment.vehicle) + ')'"></div>
            </div>

            <div class="column middle-spaceholder"></div>
            <div class="column _middle">
              <div class="line" [ngClass]="{'stroke': isWalk(segment)}"></div>
              <div class="point"></div>
            </div>

            <div class="column _right">
              <div class="name" (click)="focusStation(segment.from, segment, $event)">{{segment.from.name}}</div>
              <div class="description" *ngIf="!isWalk(segment)">
                <span *ngIf="segment.lineInfo.name; let name">
                  Einsteigen in Linie {{ name }},
                </span>
                <span *ngIf="segment.from.gleis; let gleis">
                  Perron {{ gleis }},
                </span>
                <span *ngIf="segment.headsign; let name">
                  Richtung {{ name }}</span>
              </div>
            </div>

            <div class="expand-icon"
                 *ngIf="segment.hasSubsegments"
                 [ngClass]="{'expanded': segment.expanded}">
            </div>
            <div *ngIf="segment.hasSubsegments" class="subsegments" [id]="'segment'+segment.id">

              <div *ngFor="let subsegment of segment.activSubsegments; let $index = index">

                <div *ngIf="$index !== 0" class="subsegment">
                  <div class="column _left">
                    <div class="time">{{subsegment.departure | date: 'HH:mm'}}
                      <div class="delay">{{subsegment.departureDelay}}</div>
                    </div>
                  </div>

                  <div class="column middle-spaceholder"></div>
                  <div class="column _middle">
                    <div class="line" [ngClass]="{'stroke': isWalk(segment)}"></div>
                    <div class="point"></div>
                  </div>

                  <div class="column _right">
                    <div class="name" (click)="focusStation(subsegment.from, segment, $event)">
                      {{subsegment.from.name}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <div class="segment-row _arrival">
            <div class="column _left">
              <div class="time">{{getDestination(route).arrival | date: 'HH:mm'}}
                <div class="delay">{{getDestination(route).arrivalDelay}}</div>
              </div>
              <div class="vehicle-icon"></div>
            </div>

            <div class="column middle-spaceholder"></div>
            <div class="column _middle">
              <div class="point"></div>
            </div>

            <div class="column _right">
              <div class="name" (click)="focusStation(route.lastSegment.to, route.lastSegment, $event)">
                {{getDestination(route).to.name}}
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="nothing-found" *ngIf="locationIntelligenceService.currentRoutes.length < 1">
        Es wurden keine Suchergebnisse gefunden.
      </div>

    </div>


  `,
})
export class RoutingComponent implements AfterViewInit {

  constructor(
    public locationIntelligenceService: LocationIntelligenceService,
    public guiService: GuiService,
    public settingService: SettingService,
  ) {
  }


  ngAfterViewInit(): void {
  }

  public getIconUrl(vehicle: Vehicle): string {
    const vehicleString = this.locationIntelligenceService.getVehicleIconString(vehicle);
    const url = `./assets/img/icon/${vehicleString}.png`;
    return url;
  }

  public isWalk(segment: SegmentLikeApi): boolean {
    return segment.vehicle === Vehicle.WALK;
  }

  public getDestination(route: Route): SegmentLikeApi {
    return route.segments[route.segments.length - 1];
  }

  public toggleSegmentDetails(segment: Segment) {
    if (!segment.focus) {
      this.locationIntelligenceService.setFocusSegment(segment);
    } else {
      this.locationIntelligenceService.removeFocusSegment(segment);
    }
  }

  public focusStation(otherStation: OtherStationLikeApi, segment: Segment, event): void {
    event.stopPropagation();
    const markerDataPreview = this.locationIntelligenceService.getMarkerPreviewFromOtherStation(otherStation, segment.vehicle);
    this.locationIntelligenceService.focusMarker(markerDataPreview);
  }

  public getTime(time) {
    return Math.floor(time / 60);
  }

}
