import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LocationIntelligenceService} from '../services/location-intelligence.service';
import 'leaflet.markercluster';
import 'polyline-encoded';
import * as $ from 'jquery';
import {SettingService} from '../services/setting-service';
import {MatDatepicker} from '@angular/material';
import {FormControl} from '@angular/forms';
import {DatePipe} from '@angular/common';


@Component({
  selector: 'app-spinner-component',
  template: `
    <div class="va1">
      <div class="va2">
        <div class="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>

    <style>

      .lds-spinner {
        font-size: 1em;
        position: relative;
        margin: auto;
        text-align: center;
        width: 1em;
        height: 1em;
      }

      .lds-spinner div {
        top: 1em;
        left: -0.5em;
        display: block;
        margin: auto;
        transform-origin: 0.5em 0.5em;
        animation: lds-spinner 1.2s linear infinite;
      }

      .lds-spinner div:after {
        content: "";
        display: block;
        position: absolute;
        top: 0.1em;
        left: 0.4531em;
        width: 0.0781em;
        height: 0.218em;
        border-radius: 20%;
        background-color: black;
      }

      @keyframes lds-spinner {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      .lds-spinner div:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s;
      }

      .lds-spinner div:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -1s;
      }

      .lds-spinner div:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.9s;
      }

      .lds-spinner div:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.8s;
      }

      .lds-spinner div:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.7s;
      }

      .lds-spinner div:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.6s;
      }

      .lds-spinner div:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.5s;
      }

      .lds-spinner div:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.4s;
      }

      .lds-spinner div:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.3s;
      }

      .lds-spinner div:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.2s;
      }

      .lds-spinner div:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.1s;
      }

      .lds-spinner div:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
      }

    </style>

  `,
})
export class SpinnerComponent {

}
