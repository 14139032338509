import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {GuiService} from './gui-service';
import {tap} from 'rxjs/operators';
import {Mode} from './setting-service';

export interface GetHeaderOptions {
  putOrPost: boolean;
  acceptJson: boolean;
}

export interface LocationIntelligenceLikeApi {
  mapCacheTimestamp: number;
  pois: PoiLikeApi[];
  stations: StationLikeApi[];
  message: any;
}

export type PoiLikeApi = PublibikeLikeApi | ParkingLikeApi;

export interface PublibikeLikeApi {
  type: PoiTypeLikeApi.publibike;
  id: string;
  name: string;
  description: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  'url': string;
  'bikesAvailable': number;
  'lastUpdate': number;
  'ebikesAvailable': number;
}

export interface ParkingLikeApi {
  type: PoiTypeLikeApi.parking;
  id: string;
  name: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  url: string;
  available: number;
  totalAvailable: number;
  lastUpdate: number;
}

export enum PoiTypeLikeApi {
  'publibike' = 'publibike',
  'parking' = 'parking',
}

export interface StationLikeApi {
  hid?: string;
  name?: string;
  coordinates?: {
    latitude: number;
    longitude: number;
  };
  maxZoomLevel?: number;
  vehicles?: any[];
  color?: string;
  routes?: [{
    routeId: string;
    name: string;
    vehicleId: number;
    borderColor: string;
    directions: [{
      key: string;
      name: string;
      kid: string;
    }];
    departures: DepartureLikeApi[];
    fcolor: string;
    bcolor: string;
  }];
  stops?: [{
    kid: string;
    coordinates: {
      latitude: number;
      longitude: number;
    }
    bearing: number;
  }];
}

export interface DepartureLikeApi {
  departureTripId: string;
  departureTime: string;
  direction: string;
  kid: string;
  directionKey: string;
  departureDate: number;
  polylines: string[];
}

export interface RouteLikeApi {
  switched: number;
  timeOnRoute: number;
  segments: SegmentLikeApi[];
}

export interface SegmentLikeApi {
  subsegments: SubsegmentLikeApi[];
  activSubsegments: SubsegmentLikeApi[];
  lineInfo: {
    name: string;
    fColor: number;
    bColor: number;
    borderColor: number;
    fColorHex: string;
    bColorHex: string;
    borderColorHex: string;
    fcolor: number;
    bcolor: number;
    fcolorHex: string;
    bcolorHex: string;
  };
  vehicle: number;
  operator: string;
  zugNr: string;
  messages: string[];
  attributes: [{
    attr: string;
    text: string;
  }];
  tripid: string;
  routeKey: string;
  headsign: string;
  agencyId: string;
  from: OtherStationLikeApi;
  to: OtherStationLikeApi;
  duration: string;
  changeLine: boolean;
  walk: boolean;
  needsReservation: boolean;
  departureDelay: string;
  arrival: string;
  arrivalDelay: string;
  departure: string;
}

export interface SubsegmentLikeApi {
  from: OtherStationLikeApi;
  to: OtherStationLikeApi;
  activ: boolean;
  departure: string;
  arrival: string;
  realDeparture: string;
  realArrival: string;
  duration: string;
  departureDelay: string;
  arrivalDelay: string;
  points?: string;
}

export enum Vehicle {
  'TRAM',
  'BUS',
  'TROLLEY',
  'METRO',
  'RAIL',
  'CARTRAIN',
  'BOAT',
  'CABLECAR',
  'GONDOLA',
  'FUNICULAR',
  'WALK',
  'TAXI',
  'FLINC',
}

export interface LatLng {
  lat: number;
  lng: number;
}

export interface Bounds {
  _northEast: LatLng;
  _southWest: LatLng;
}

export interface StopLikeApi {
  hid: string;
  stationName: string;
  dateTime: number;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  realtime: boolean;
}

export interface TripLikeApi {
  points: string;
  polylines: string[];
  stops: StopLikeApi[];
}

export interface NodeLikeApi {
  name: string;
  key: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  type: string;
}

export interface OtherStationLikeApi {
  id: string;
  name: string;
  gleis: string;
  latitude: number;
  longitude: number;
  hid: string;
}

export interface AutocompleteResultLikeApi {
  name: string;
  key: string;
  coordinates?: {
    latitude: number;
    longitude: number;
  };
  type: string;
}

@Injectable()
export class ApiService {

  constructor(
    private httpClient: HttpClient,
    private guiService: GuiService
  ) {
  }

  public handleError(error: HttpErrorResponse): Observable<any> {
    return of(null);
  }


  public getHeader(options?: GetHeaderOptions): HttpHeaders {
    const headersObject: any = {};
    if (options && options.putOrPost) {
      headersObject['Content-Type'] = 'application/json';
    }
    if (options && options.acceptJson) {
      headersObject['Accept'] = 'application/json';
    }
    return new HttpHeaders(headersObject);
  }

  public getLocationIntelligence(boundary: Bounds, zoom: number): Observable<LocationIntelligenceLikeApi> {
    // https://webplattform-develop.bernmobil.ch/api/mobile/v2/infoExtended?app=fagime&latNE=46.95045266213809&lngNE=7.449481487274171&latSW=46.938733249261425&lngSW=7.423732280731202&zoom=16
    const requestId = JSON.stringify(boundary) + zoom;
    this.guiService.addRequestId(requestId);
    const url = 'api/mobile/v2/infoExtended';
    let params = new HttpParams();
    params = params.set('app', 'fagime');
    params = params.set('latNE', '' + boundary._northEast.lat);
    params = params.set('lngNE', '' + boundary._northEast.lng);
    params = params.set('latSW', '' + boundary._southWest.lat);
    params = params.set('lngSW', '' + boundary._southWest.lng);
    params = params.set('zoom', '' + zoom);
    params = params.set('addDepartures', 'false');

    return this.httpClient.get<LocationIntelligenceLikeApi>(url, {params: params, headers: this.getHeader()})
      .pipe(tap(_ => {
        this.guiService.removeRequestId(requestId);
      }));
  }


  public getStationDetails(hid: string): Observable<StationLikeApi> {
    // https://webplattform-develop.bernmobil.ch/api/mobile/v2/station?app=fagime&hid=85:7000
    const url = 'api/mobile/v2/station';
    let params = new HttpParams();
    params = params.set('app', 'fagime');
    params = params.set('hid', hid);
    return this.httpClient.get<StationLikeApi>(url, {params: params, headers: this.getHeader()});
  }


  public getPoiDetails(id: string): Observable<PoiLikeApi> {
    // https://webplattform-develop.bernmobil.ch/api/mobile/v2/poi?app=fagime&id=P01
    const url = 'api/mobile/v2/poi';
    let params = new HttpParams();
    params = params.set('app', 'fagime');
    params = params.set('id', id);
    return this.httpClient.get<PoiLikeApi>(url, {params: params, headers: this.getHeader()});
  }


  public getDepartureTrip(departureTripId: string): Observable<TripLikeApi> {
    // https://webplattform-develop.bernmobil.ch/api/mobile/v2/trip?app=fagime&departureTripId=000011_00050_7:::6:::2019-07-04
    const url = 'api/mobile/v2/trip';
    let params = new HttpParams();
    params = params.set('app', 'fagime');
    params = params.set('departureTripId', departureTripId);
    return this.httpClient.get<TripLikeApi>(url, {params: params, headers: this.getHeader()});
  }

  public getNode(latLng: LatLng): Observable<NodeLikeApi> {
    // https://webplattform-develop.bernmobil.ch/api/mobile/v2/node?app=fagime&latitude=46.0&longitude=7.5
    const url = 'api/mobile/v2/node';
    let params = new HttpParams();
    params = params.set('app', 'fagime');
    params = params.set('latitude', '' + latLng.lat);
    params = params.set('longitude', '' + latLng.lng);
    return this.httpClient.get<NodeLikeApi>(url, {params: params, headers: this.getHeader()});
  }

  public getRouting(fromNodeId: string, toNodeId: string, viaNodeId?: string, dateTime?: Date, mode?: Mode): Observable<RouteLikeApi[]> {
    // https://webplattform-develop.bernmobil.ch/api/mobile/v2/routing?app=fagime&from=85:7000:6&to=:85:11:EC&via=85:7000:6&dateTime=201907041041
    // https://webplattform-develop.bernmobil.ch/api/mobile/v2/routing?app=fagime&from=85:7000&to=85:88699&dateTime=201907041041
    const url = 'api/mobile/v2/routing';
    let params = new HttpParams();
    params = params.set('app', 'fagime');
    params = params.set('from', fromNodeId);
    params = params.set('to', toNodeId);
    if (viaNodeId) {
      params = params.set('via', viaNodeId);
    }
    if (!dateTime) {
      dateTime = new Date();
    }
    if (mode === Mode.from || mode === Mode.to) {
      params = params.set('mode', mode);
    }
    params = params.set('dateTime', this.convertDateForApi(dateTime));
    return this.httpClient.get<RouteLikeApi[]>(url, {params: params, headers: this.getHeader()});
  }

  public convertDateForApi(dateTime: Date): string {
    // yyyyMMddHHmm
    let apiDateTime = '';
    apiDateTime += ('0000' + (dateTime.getFullYear())).slice(-4);
    apiDateTime += ('0' + (dateTime.getMonth() + 1)).slice(-2);
    apiDateTime += ('0' + (dateTime.getDay())).slice(-2);
    apiDateTime += ('0' + (dateTime.getHours())).slice(-2);
    apiDateTime += ('0' + (dateTime.getMinutes())).slice(-2);
    return apiDateTime;
  }

  public getAutocomplete(needle: string, latLng?: LatLng): Observable<AutocompleteResultLikeApi[]> {
    // https://webplattform-develop.bernmobil.ch/webplattform/mobile/geo/search?webshop=true&app=fagime&latitude=46.9437556&needle=glue&longitude=7.4334601
    const url = 'webplattform/mobile/geo/search';
    let params = new HttpParams();
    params = params.set('webshop', 'true');
    params = params.set('app', 'fagime');
    params = params.set('needle', needle);
    if (latLng) {
      params = params.set('latitude', '' + latLng.lat);
      params = params.set('longitude', '' + latLng.lng);
    }
    return this.httpClient.get<AutocompleteResultLikeApi[]>(url, {params: params, headers: this.getHeader()});
  }
}
