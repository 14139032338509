import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <app-map-component></app-map-component>
  `,
})
export class AppComponent {

}
