import {Injectable} from '@angular/core';
import {
  ApiService,
  AutocompleteResultLikeApi,
  Bounds,
  DepartureLikeApi,
  LatLng,
  LocationIntelligenceLikeApi,
  NodeLikeApi,
  OtherStationLikeApi,
  PoiLikeApi,
  PoiTypeLikeApi,
  RouteLikeApi,
  SegmentLikeApi,
  StationLikeApi,
  StopLikeApi,
  TripLikeApi,
  Vehicle
} from './api-service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {GuiService} from './gui-service';
import {MapService} from './map-service';
import {SettingService} from './setting-service';

export enum MarkerType {
  'STATION' = 'STATION',
  'POI_DEFAULT' = 'POI_DEFAULT',
  'POI_PUBLIBIKE' = 'POI_PUBLIBIKE',
  'POI_PARKING' = 'POI_PARKING',
  'CUSTOM_PIN' = 'CUSTOM_PIN',
}

export interface Route extends RouteLikeApi {
  id: string;
  expanded: boolean;
  segments: Segment[];
  firstSegment: Segment;
  lastSegment: Segment;
}

export interface Segment extends SegmentLikeApi {
  id: string;
  hasSubsegments: boolean;
  expanded: boolean;
  focus: boolean;
}

export interface MarkerDataPreview {
  id: string;
  name: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  markerType: MarkerType;
  vehicle?: Vehicle;
}

export interface Departure extends DepartureLikeApi {
  route: {
    routeId: string;
    name: string;
    vehicleId: Vehicle;
    borderColor: string;
    fcolor: string;
    bcolor: string;
  };
  departureTrip?: TripLikeApi;
}


export type MarkerDataDetails = PoiLikeApi | StationLikeApi;


@Injectable()
export class LocationIntelligenceService {

  public currentLocationIntelligenceMarkerArray: MarkerDataPreview[];
  public currentRoutes: Route[] = [];

  constructor(
    private apiService: ApiService,
    private guiService: GuiService,
    private mapService: MapService,
    private settingService: SettingService,
  ) {
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public resetRoutes() {
    this.currentRoutes = [];
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public getLocationIntelligenceAsMarkerArray(bounds: Bounds, zoom: number): Observable<MarkerDataPreview[]> {
    return this.apiService.getLocationIntelligence(bounds, zoom)
      .pipe(map((locationIntelligenceLikeApi: LocationIntelligenceLikeApi) => {
        const markerDataArray: MarkerDataPreview[] = [];
        locationIntelligenceLikeApi.stations.forEach((stationLikeApi: StationLikeApi) => {
          const markerDataPreview: MarkerDataPreview = {
            id: stationLikeApi.hid,
            name: stationLikeApi.name,
            coordinates: {
              latitude: stationLikeApi.coordinates.latitude,
              longitude: stationLikeApi.coordinates.longitude,
            },
            markerType: MarkerType.STATION,
            vehicle: stationLikeApi.vehicles[0]
          };
          markerDataArray.push(markerDataPreview);
        });
        locationIntelligenceLikeApi.pois.forEach((poiLikeApi: PoiLikeApi) => {
          let type = MarkerType.POI_DEFAULT;
          if (poiLikeApi.type === PoiTypeLikeApi.parking) {
            type = MarkerType.POI_PARKING;
          } else if (poiLikeApi.type === PoiTypeLikeApi.publibike) {
            type = MarkerType.POI_PUBLIBIKE;
          }
          const markerDataPreview: MarkerDataPreview = {
            id: poiLikeApi.id,
            name: poiLikeApi.name,
            coordinates: {
              latitude: poiLikeApi.coordinates.latitude,
              longitude: poiLikeApi.coordinates.longitude,
            },
            markerType: type
          };
          markerDataArray.push(markerDataPreview);
        });
        this.currentLocationIntelligenceMarkerArray = markerDataArray;
        return markerDataArray;
      }));
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public getNextNode(latLng: LatLng): Observable<NodeLikeApi> {
    return this.apiService.getNode(latLng);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public getMarkerDataDetails(markerDataPreview: MarkerDataPreview): Observable<MarkerDataDetails> {
    if (markerDataPreview.markerType.indexOf('POI') > -1) {
      return this.apiService.getPoiDetails(markerDataPreview.id);
    }
    if (markerDataPreview.markerType.indexOf('STATION') > -1) {
      return this.apiService.getStationDetails(markerDataPreview.id);
    }
    return of(null);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public arrangeDeparturesForStation(station: StationLikeApi, amount: number): Departure[] {
    const departures: Departure[] = [];
    station.routes.forEach(route => {
      route.departures.forEach((departure: Departure) => {
        if (departure) {
          departure.route = {
            routeId: route.routeId,
            name: route.name,
            vehicleId: route.vehicleId,
            fcolor: route.fcolor,
            bcolor: route.bcolor,
            borderColor: route.borderColor
          };
          departures.push(departure);
        }
      });
    });
    departures.sort((a, b) => {
      return a.departureDate - b.departureDate;
    });
    const departuresByAmount: Departure[] = departures.slice(0, amount);
    return departuresByAmount as Departure[];
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public getVehicleIconString(vehicleIndex: number): string {
    return Vehicle[vehicleIndex];
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public refreshRouting() {
    if (this.settingService.allRoutingMarkerAreDefined()) {
      const reasonIdForMainSpinner = '' + Math.random().toFixed(8);
      this.unfocusMarker();
      const startMarker = this.settingService.getCurrentRoutingMarker()[0].id;
      const endMarker = this.settingService.getCurrentRoutingMarker()[1].id;
      const dateTime = this.settingService.getDateTime();
      const mode = this.settingService.getMode();
      this.guiService.addReasonForMainSpinner(reasonIdForMainSpinner);
      this.apiService.getRouting(startMarker, endMarker, null, dateTime, mode).subscribe(
        (routesLikeApi: RouteLikeApi[]) => {
          this.guiService.removeReasonForMainSpinner(reasonIdForMainSpinner);
          const routes: Route[] = [];
          routesLikeApi.forEach((routeLikeApi: RouteLikeApi, routeIndex: number) => {
            const route: Route = routeLikeApi as Route;
            route.id = '' + routeIndex;
            route.expanded = false;
            // route.lastSegment = routeLikeApi.segments[routeLikeApi.segments.length - 1];
            route.segments.forEach((segment: Segment, segmentIndex) => {
              this.mapService.completePointsWithMarkerPosition(segment);
              segment.id = routeIndex + '_' + segmentIndex;
              segment.hasSubsegments = (segment.subsegments.length > 1) ? true : false;
              segment.expanded = false;
            });
            route.firstSegment = route.segments[0];
            route.lastSegment = route.segments[route.segments.length - 1];
            routes.push(route);
          });
          this.currentRoutes = routes;
          setTimeout(() => {
            this.setRoute(this.currentRoutes[0]);
          }, 200);
        }
      );
    } else {
      this.currentRoutes = [];
      this.mapService.removeRoute();
      this.setRoutingStartAndEndMarker();
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public exchangeRoutingMarker() {
    const A = JSON.parse(JSON.stringify(this.settingService.getCurrentRoutingMarker()[0]));
    const B = JSON.parse(JSON.stringify(this.settingService.getCurrentRoutingMarker()[1]));
    this.settingService.resetAllRoutingMarker();
    this.defineRoutingStartingMarker(B);
    this.defineRoutingEndingMarker(A);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public defineRoutingStartingMarker(markerDataPreview: MarkerDataPreview, focusIt?: boolean) {
    this.settingService.setCurrentRoutingMarker(0, markerDataPreview);
    if (this.settingService.getCurrentRoutingMarker()[1] &&
      this.settingService.getCurrentRoutingMarker()[1].id === markerDataPreview.id) {
      this.settingService.setCurrentRoutingMarker(1, null);
    }
    this.setRoutingStartAndEndMarker();
    if (focusIt) {
      if (!this.settingService.allRoutingMarkerAreDefined()) {
        this.focusMarker(markerDataPreview);
      }
    } else {
      this.unfocusMarker();
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public defineRoutingEndingMarker(markerDataPreview: MarkerDataPreview, focusIt?: boolean) {
    this.settingService.setCurrentRoutingMarker(1, markerDataPreview);
    if (this.settingService.getCurrentRoutingMarker()[0] &&
      this.settingService.getCurrentRoutingMarker()[0].id === markerDataPreview.id) {
      this.settingService.setCurrentRoutingMarker(0, null);
    }
    this.setRoutingStartAndEndMarker();
    if (focusIt) {
      this.focusMarker(markerDataPreview);
    } else {
      this.unfocusMarker();
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public focusMarker(markerDataPreview: MarkerDataPreview, fitBound?: boolean) {
    this.mapService.printFocusMarker(markerDataPreview, fitBound);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public unfocusMarker() {
    this.mapService.removeFocusMarker();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public setRoute(focusRoute: Route) {
    this.removeAllFocusSegment();
    this.guiService.sidePanelIsExpanded = true;
    this.currentRoutes.forEach((route: Route) => {
      if (route.id === focusRoute.id) {
        route.expanded = true;
      } else {
        route.expanded = false;
      }
    });
    this.guiService.refreshRouteExpansions(this.currentRoutes);
    setTimeout(() => {
      this.mapService.printRoute(focusRoute);
    }, 200);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public setTemporaryFocusSegment(segment: Segment): void {
    this.mapService.printFocusSegment(segment, true);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public removeTemporaryFocusSegment(): void {
    this.mapService.removeFocusSegment(true);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public setFocusSegment(focusSegment: Segment) {
    this.guiService.sidePanelIsExpanded = true;
    this.currentRoutes.forEach((route: Route) => {
      route.segments.forEach((segment: Segment) => {
        if (focusSegment.id === segment.id) {
          segment.expanded = true;
          segment.focus = true;
        } else {
          segment.expanded = false;
          segment.focus = false;
        }
      });
    });
    this.guiService.refreshRouteExpansions(this.currentRoutes);
    setTimeout(() => {
      this.mapService.printFocusSegment(focusSegment, false);
    }, 200);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public removeFocusSegment(segment: Segment) {
    segment.focus = false;
    segment.expanded = false;
    this.guiService.refreshRouteExpansions(this.currentRoutes);
    this.mapService.removeFocusSegment(false);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public removeAllFocusSegment() {
    this.currentRoutes.forEach((route: Route) => {
      route.segments.forEach((segment: Segment) => {
        segment.focus = false;
        segment.expanded = false;
      });
    });
    this.guiService.refreshRouteExpansions(this.currentRoutes);
    this.mapService.removeFocusSegment(false);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public makeDepartureTrip(departure: Departure) {
    this.apiService.getDepartureTrip(departure.departureTripId)
      .subscribe((departureTrip: TripLikeApi) => {
        departure.departureTrip = departureTrip;
        this.mapService.printDepartureTrip(departure);
      });
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public getMarkerPreviewFromStop(stop: StopLikeApi, vehicle: Vehicle): MarkerDataPreview {
    return {
      id: stop.hid,
      name: stop.stationName,
      markerType: MarkerType.STATION,
      vehicle: vehicle,
      coordinates: {
        latitude: stop.coordinates.latitude,
        longitude: stop.coordinates.longitude
      }
    };
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public getAutocomplete(needle: string): Observable<AutocompleteResultLikeApi[]> {
    const latLng: LatLng = this.mapService.getMapCenter();
    return this.apiService.getAutocomplete(needle, latLng);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public getGeolocation(callback) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position.coords && position.coords.latitude) {
          const latLng: LatLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          callback(latLng);
        } else {
          callback(null);
        }
      }, (error) => {
        callback(null);
      });
    } else {
      callback(null);
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public getMarkerPreviewFromOtherStation(otherStation: OtherStationLikeApi, vehicle: Vehicle): MarkerDataPreview {
    if (vehicle === Vehicle.WALK) {
      return {
        id: otherStation.id,
        name: otherStation.name,
        markerType: MarkerType.CUSTOM_PIN,
        coordinates: {
          latitude: otherStation.latitude,
          longitude: otherStation.longitude
        }
      };
    } else {
      return {
        id: otherStation.id,
        name: otherStation.name,
        markerType: MarkerType.STATION,
        vehicle: vehicle,
        coordinates: {
          latitude: otherStation.latitude,
          longitude: otherStation.longitude
        },
      };
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public refreshLocationIntelligence() {
    this.getLocationIntelligenceAsMarkerArray(this.mapService.getBoundsAndZoom().bounds, this.mapService.getBoundsAndZoom().zoom)
      .subscribe((locationIntelligenceMarkerDataPreview: MarkerDataPreview[]) => {
        this.mapService.printLocationIntelligenceMarker(locationIntelligenceMarkerDataPreview);
        this.setRoutingStartAndEndMarker();
      });
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public setRoutingStartAndEndMarker() {
    this.mapService.printRouteStartAndEndMarker(
      this.settingService.getCurrentRoutingMarker()[0],
      this.settingService.getCurrentRoutingMarker()[1]
    );
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - //

  public resetLocationIntelligence() {
    this.settingService.resetCustomDateTime();
    this.settingService.resetAllRoutingMarker();
    this.settingService.resetCustomDateTime();
    this.mapService.resetAllMapLayer();
    this.refreshLocationIntelligence();
    this.resetRoutes();
    this.guiService.closePanel();
  }
}
