import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {
  Departure,
  LocationIntelligenceService,
  MarkerDataDetails,
  MarkerDataPreview,
  MarkerType
} from '../services/location-intelligence.service';
import * as $ from 'jquery';
import {MapComponent} from './map.component';
import {SettingService} from '../services/setting-service';
import {Vehicle} from '../services/api-service';


@Component({
  selector: 'app-poup-component',
  template: `

    <div class="lic-marker-popup">
      <div class="popover-header">
        <h4>{{markerDataPreview.name}}</h4>
        <span class="fade-in" *ngIf="showSpinner">
          <div class="spinner-container fade-in"></div>
        </span>
      </div>
      <div #popoverBody class="popover-body">

        <div class="routing">
          <button class="routing-button _A" (click)="locationIntelligenceService.defineRoutingStartingMarker(markerDataPreview)">
            Als Startpunkt für Routing definieren
          </button>
          <button class="routing-button _B" (click)="locationIntelligenceService.defineRoutingEndingMarker(markerDataPreview)">
            Als Zielpunkt für Routing definieren
          </button>
        </div>
        <div class="information" *ngIf="markerDataDetails">
          <h5 class="title">{{getTitle()}}</h5>
          <div class="line"></div>

          <!-- STATION -->
          <div class="station" *ngIf="markerDataPreview.markerType === MarkerType.STATION">
            <p>Nächste Abfahrten:</p>
            <button *ngFor="let departure of locationIntelligenceService.arrangeDeparturesForStation(markerDataDetails, 10)"
                    class="departure-button"
                    [ngClass]="{'active': tripIsActive(departure)}"
                    (click)="clickOnDeparture(departure)">
              <span class="column _line">
                <div class="line-icon" [ngStyle]="{'background-color':departure.route.bcolor, 'color':departure.route.fcolor }">
                  {{departure.route.name}}
                </div>
              </span>
              <span class="column _direction">nach {{ departure.direction }}</span>
              <span class="column _time">{{departure.departureTime}}</span>
            </button>
          </div>

          <!-- POI PARKING -->
          <div class="poi _parking" *ngIf="markerDataPreview.markerType === MarkerType.POI_PARKING">
            <p>Anzahl Parkplätze: {{ markerDataDetails.totalAvailable }}</p>
            <p>Momentan Frei: {{ markerDataDetails.available }}</p>
            <div class="line" *ngIf="markerDataDetails.url"></div>
            <p *ngIf="markerDataDetails.url"><a [href]="markerDataDetails.url" target="_blank">Link zu {{ markerDataDetails.name }}</a>
            </p>
          </div>

          <!-- POI PUBLIBIKE -->
          <div class="poi _publibike" *ngIf="markerDataPreview.markerType === MarkerType.POI_PUBLIBIKE">
            <p>Anzahl Bikes verfügbar: {{ markerDataDetails.bikesAvailable }}</p>
            <p>Anzahl E-Bikes verfügbar: {{ markerDataDetails.ebikesAvailable }}</p>
            <div class="line" *ngIf="markerDataDetails.url"></div>
            <p *ngIf="markerDataDetails.url"><a [href]="markerDataDetails.url" target="_blank">Link zu {{ markerDataDetails.name }}</a>
            </p>
          </div>
        </div>

      </div>
    </div>

  `,
})
export class PopupComponent implements OnInit, AfterViewInit {

  @Input() params: any;
  @ViewChild('popoverBody', {static: false}) popoverBody: ElementRef;

  public mapComponent: MapComponent;

  public MarkerType = MarkerType;
  public markerDataPreview: MarkerDataPreview;
  public markerDataDetails: MarkerDataDetails = null;
  public showSpinner = false;

  constructor(
    public locationIntelligenceService: LocationIntelligenceService,
    public settingService: SettingService,
  ) {
  }

  getTitle(): string {
    let text = '';
    if (this.markerDataPreview.markerType === MarkerType.STATION) {
      if (this.markerDataPreview.vehicle === Vehicle.TRAM) {
        text += 'Tram-';
      } else if (this.markerDataPreview.vehicle === Vehicle.BOAT) {
        text += 'Schiff-';
      } else if (this.markerDataPreview.vehicle === Vehicle.BUS) {
        text += 'Bus-';
      } else if (this.markerDataPreview.vehicle === Vehicle.CABLECAR) {
        text += 'Seilbahn-';
      } else if (this.markerDataPreview.vehicle === Vehicle.CARTRAIN) {
        text += 'Autoverlad-';
      } else if (this.markerDataPreview.vehicle === Vehicle.FUNICULAR) {
        text += 'Drahtseilbahn-';
      } else if (this.markerDataPreview.vehicle === Vehicle.GONDOLA) {
        text += 'Gondel-';
      } else if (this.markerDataPreview.vehicle === Vehicle.METRO) {
        text += 'Metro-';
      } else if (this.markerDataPreview.vehicle === Vehicle.RAIL) {
        text += 'Zug-';
      }
      text += 'Station ';
    } else if (this.markerDataPreview.markerType === MarkerType.POI_PUBLIBIKE) {
      text += 'Publibike-Station ';
    } else if (this.markerDataPreview.markerType === MarkerType.POI_PARKING) {
      text += 'Parking ';
    }
    return text;
  }

  tripIsActive(departure: Departure): boolean {
    return this.settingService.getCurrentDeparture() && departure.departureTripId === this.settingService.getCurrentDeparture().departureTripId;
  }

  clickOnDeparture(departure: Departure) {
    this.settingService.setCurrentDeparture(departure);
  }

  ngOnInit() {
    this.markerDataPreview = this.params.markerDataPreview;
    this.mapComponent = this.params.mapComponent;
  }

  ngAfterViewInit() {
    let hasLoaded = false;
    setTimeout(() => {
      if (hasLoaded === false) {
        this.showSpinner = true;
      }
    }, 500);
    this.locationIntelligenceService.getMarkerDataDetails(this.markerDataPreview).subscribe(markerDataDetails => {
      this.markerDataDetails = markerDataDetails;
      setTimeout(() => {
        hasLoaded = true;
        this.showSpinner = false;
        if (this.popoverBody) {
          $(this.popoverBody.nativeElement).slideDown(200);
        }
      }, 50);
    });
  }
}


