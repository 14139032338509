import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LocationIntelligenceService} from '../services/location-intelligence.service';
import 'leaflet.markercluster';
import 'polyline-encoded';
import * as $ from 'jquery';
import {Mode, SettingService} from '../services/setting-service';
import {MatDatepicker} from '@angular/material';
import {FormControl} from '@angular/forms';
import {DatePipe} from '@angular/common';


@Component({
  selector: 'app-time-component',
  template: `

    <div class="date-time">
      <div class="va1">
        <div class="va2">
          <button (click)="timePicker.toggle()" class="date-time-button _time">
            <div class="va1">
              <div class="va2">
                <div class="clock">
                  <div class="part _clock-face"></div>
                  <div class="part _second-watch-hand"></div>
                  <div class="part _hour-watch-hand"></div>
                  <div class="part _minute-watch-hand"></div>
                </div>
                <div class="number" [innerHTML]="getTimeText()"></div>
              </div>
            </div>
          </button>
          <div class="lic-timepicker-popover _time-picker" *ngIf="timePicker.show">
            <div class="content">
              <input type="time" [(ngModel)]="timePicker.time">
            </div>
            <div class="footer">
              <button class="cancel" (click)="timePicker.close()">Abbrechen</button>
              <button class="ok" (click)="timePicker.save()">OK</button>
            </div>
          </div>

          <button (click)="openDatepicker()" class="date-time-button _date">
            <div class="va1">
              <div class="va2">
                <div class="date">
                  <div class="calendar-icon"></div>
                  {{ getDateText() }}
                  <mat-form-field>
                    <input matInput [formControl]="date"
                           (dateChange)="onDatePickerChange($event)"
                           [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
    
    <div class="mode">
      <button class="from"
              (click)="settingService.setMode(Mode.from)"
              [ngClass]="{'active': settingService.getMode() === Mode.from}">
        ab
      </button>
      <button class="to"
              (click)="settingService.setMode(Mode.to)"
              [ngClass]="{'active': settingService.getMode() === Mode.to}">
        an
      </button>
    </div>

  `,
})
export class DateTimeComponent implements OnInit, OnDestroy, AfterViewInit {

  public Mode = Mode;
  @Input() timeParams: any;
  private clockAnimationInterval;
  @ViewChild(MatDatepicker, {static: false}) datepicker: MatDatepicker<Date>;
  public date = new FormControl();

  public dateTime: Date = this.settingService.getDateTime();

  public timePicker = {
    show: false,
    time: null,
    toggle: () => {
      if (this.timePicker.show) {
        this.timePicker.close();
      } else {
        this.timePicker.open();
      }
    },
    open: () => {
      this.timePicker.show = true;
      this.timePicker.time = this.settingService.getTimeOfDateTime();
    },
    close: () => {
      this.timePicker.show = false;
    },
    save: () => {
      this.settingService.setTimeOfCustomDateTime(this.timePicker.time);
      this.timePicker.close();
      this.refreshDateTime();
    }
  };

  constructor(
    public locationIntelligenceService: LocationIntelligenceService,
    public settingService: SettingService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    clearInterval(this.clockAnimationInterval);
  }

  ngAfterViewInit() {
    this.refreshDateTime();
    this.clockAnimationInterval = setInterval(() => {
      this.refreshDateTime();
    }, 1000);
  }

  public openDatepicker() {
    this.datepicker.open();
  }

  public getDateText(): string {
    if (this.dateIsToday(this.dateTime)) {
      return 'heute';
    } else {
      return this.datePipe.transform(this.dateTime, 'd.M.yyyy');
    }
  }

  public getTimeText(): string {
    let text = this.datePipe.transform(this.dateTime, 'HH:mm');
    if (this.timeIsNow(this.dateTime)) {
      text += '<br>(jetzt)';
    }
    return text;
  }

  public dateIsToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    );
  }

  public timeIsNow(dateTime: Date): boolean {
    const now = new Date();
    const diff = Math.abs(dateTime.getTime() - now.getTime());
    return diff < 2000;
  }

  public onDatePickerChange(event) {
    this.settingService.setDateOfCustomDateTime(event.value);
    this.refreshDateTime();
  }

  public refreshDateTime() {
    this.dateTime = this.settingService.getDateTime();
    this.refreshClockHandPosition();
  }

  public refreshClockHandPosition() {
    const time = this.settingService.getDateTime();
    const angleOfSecond = (time.getSeconds() - 1) * 6;
    $('.clock .part._second-watch-hand').css({transform: 'rotate(' + angleOfSecond + 'deg)'});
    const angleOfMinute = time.getMinutes() * 6;
    $('.clock .part._minute-watch-hand').css({transform: 'rotate(' + angleOfMinute + 'deg)'});
    const angleOfHour = (time.getHours() * 30 + time.getMinutes() * 6 / 10);
    $('.clock .part._hour-watch-hand').css({transform: 'rotate(' + angleOfHour + 'deg)'});
  }

}
