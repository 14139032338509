import {AfterViewInit, Component, OnInit} from '@angular/core';
import {LocationIntelligenceService, MarkerDataPreview, MarkerType} from '../services/location-intelligence.service';
import {Observable, of} from 'rxjs';
import {GuiService} from '../services/gui-service';
import 'leaflet.markercluster';
import 'polyline-encoded';
import {MapService} from '../services/map-service';
import {PopupComponent} from './popup.component';
import {NodeLikeApi} from '../services/api-service';
import {SettingService} from '../services/setting-service';


@Component({
  selector: 'app-map-component',
  template: `

    <div class="map-page">

      <div class="the-header">

        <div class="container _left">
          <div class="va1">
            <div class="va2">
              <img class="logo" src="assets/img/logo/LIC_logo_negativ.png" alt="Logo LIC" (click)="guiService.goHome();">
            </div>
          </div>
        </div>

        <div class="container _middle">
          <app-search-component [autocompleteParams]="{'isFrom':true}"></app-search-component>
          <button class="exchange-route" (click)="locationIntelligenceService.exchangeRoutingMarker()"></button>
          <app-search-component [autocompleteParams]="{'isTo':true}"></app-search-component>
          <app-time-component></app-time-component>
        </div>

        <div class="container _right">
          <div class="va1">
            <div class="va2">
              <button class="reload-button" (click)="locationIntelligenceService.resetLocationIntelligence()"></button>
            </div>
          </div>
        </div>

      </div>

      <div class="the-body">

        <div id="leafletMap"></div>

        <div class="panel-container">
          <div class="panel" [ngClass]="{'expanded': guiService.sidePanelIsExpanded}">
            <button (click)="guiService.togglePanel()" class="panel-button"></button>
            <div class="panel-content">
              <app-routing-component></app-routing-component>
            </div>
          </div>
        </div>

        <div class="map-information">
          {{ getMapInformation() | async }}
        </div>

      </div>

      <div id="mainSpinner" class="main-spinner">
        <div class="spinner-container">
          <app-spinner-component></app-spinner-component>
        </div>
      </div>

    </div>

    <div *ngIf="guiService.showDebugConsole" class="debug-console">
        <pre>
          {{settingService.allRoutingMarkerAreDefined() | json}}
        </pre>

      <pre>
          {{settingService.getCurrentRoutingMarker() | json}}
        </pre>
    </div>

  `,
})
export class MapComponent implements OnInit, AfterViewInit {

  constructor(
    public locationIntelligenceService: LocationIntelligenceService,
    public guiService: GuiService,
    public mapService: MapService,
    public settingService: SettingService
  ) {
  }

  ngOnInit() {
    // to prevent circularDependenciesError
    this.mapService.locationIntelligenceService = this.locationIntelligenceService;
    this.settingService.locationIntelligenceService = this.locationIntelligenceService;
    this.guiService.locationIntelligenceService = this.locationIntelligenceService;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mapService.generateMap();
      if (this.guiService.getDebugModeState()) {
        this.makeDebugRouting();
      }
    }, 200);

    // this.guiService.addReasonForMainSpinner('hhh');
  }

  private makeDebugRouting() {
    setTimeout(() => {
      if (this.guiService.getDebugModeState()) {
        this.settingService.setCurrentRoutingMarker(0, {
          id: '85:76990',
          name: 'Bern, Guisanplatz Expo',
          coordinates: {
            latitude: 46.9596,
            longitude: 7.464054,
          },
          markerType: MarkerType.STATION,
          vehicle: 0
        });
        this.locationIntelligenceService.getNextNode({lat: 46.892900, lng: 7.363501})
          .subscribe((nodeLikeApi: NodeLikeApi) => {
            const markerDataPreview: MarkerDataPreview = {
              name: nodeLikeApi.name,
              id: nodeLikeApi.key,
              coordinates: nodeLikeApi.coordinates,
              markerType: MarkerType.CUSTOM_PIN,
            };
            this.settingService.setCurrentRoutingMarker(1, markerDataPreview);
          });
      }
    }, 1500);
  }


  getMapInformation(): Observable<string> {
    let info = '';
    if (this.mapService.map) {
      info += 'lat: ' + this.mapService.map.getCenter().lat.toFixed(6);
      info += ' | lng: ' + this.mapService.map.getCenter().lng.toFixed(6);
      info += ' | zoom: ' + this.mapService.map.getZoom();
    }
    return of(info);
  }


}
